import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const Home = React.lazy(() => import('./pages/Home'));
const CategoryPage = React.lazy(() => import('./pages/CategoryPage'));
const GroceryPage = React.lazy(() => import('./pages/GroceryPage'));


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category" element={<CategoryPage />} />
          <Route path="/grocery" element={<GroceryPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
